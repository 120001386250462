.dashboard-page
{
    .ant-layout-sider
    {
        background-color: transparent;
        height: calc(100vh - 64px - 48px);
        position: fixed;
        left: 0;
        overflow: auto;
        user-select: none;

        &.ant-layout-sider-has-trigger
        {
            padding-bottom: 0;
        }
        &.ant-layout-sider-collapsed
        {
            background-color: white;

            .ant-layout-sider-children
            {
                .ant-menu
                {
                    .ant-menu-submenu
                    {
                        &.ant-menu-submenu-selected
                        {
                            background-color: black;

                            & > .ant-menu-submenu-title
                            {
                                color: white;
                            }
                        }
                    }
                    .ant-menu-item
                    {
                        &.ant-menu-item-selected
                        {
                            background-color: black;
                        }
                    }
                }
            }
        }

        .ant-layout-sider-children
        {
            .ant-menu
            {
                background-color: inherit;

                .ant-menu-submenu
                {
                    & > .ant-menu-submenu-title
                    {
                        color: black;

                        &:active
                        {
                            background-color: transparent;
                        }

                        & > .ant-menu-submenu-arrow
                        {
                            color: inherit;
                        }
                    }
                }
                .ant-menu-item
                {
                    color: black;

                    &:active
                    {
                        background-color: transparent;
                    }
                    &:after
                    {
                        border-right: none;
                        border-top-right-radius: 100px;
                        border-bottom-right-radius: 100px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 100%;
                        transform: scale(1);
                        transition: 0.2s;
                        z-index: -1;
                    }

                    &.ant-menu-item-selected
                    {
                        color: white;
                        background-color: transparent;
                        position: relative;
                        z-index: 1;

                        &:after
                        {
                            background-color: black;
                            right: 0;
                        }
                    }

                    a
                    {
                        color: inherit;
                    }
                }
            }
        }

        .ant-layout-sider-trigger
        {
            color: black;
            background-color: inherit;
        }
    }
    .ant-menu-submenu-popup
    {
        .ant-menu-item
        {
            color: black;
            background-color: white;

            &.ant-menu-item-selected
            {
                color: white;
                background-color: black;
            }

            a
            {
                color: inherit;
            }
        }
    }


    .ant-layout-content
    {
        margin-right: 24px;
        transition: margin-left 0.2s;

        .content-panel-container
        {
            max-width: 1440px;
            margin: 24px auto 48px;

            .ant-tabs-nav
            {
                margin: 0;

                .ant-tabs-tab
                {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    margin-right: 0;
                    padding: 8px 32px;

                    &:hover
                    {
                        color: black;
                        opacity: 0.7;

                        &.ant-tabs-tab-active
                        {
                            opacity: 1;
                        }
                    }

                    &.ant-tabs-tab-active
                    {
                        background-color: white;

                        .ant-tabs-tab-btn
                        {
                            color: black;
                        }
                    }
                }
            }
            .content-panel
            {
                margin: 0;
            }
        }
        .content-panel
        {
            background-color: white;
            max-width: 1440px;
            margin: 24px auto;
            padding: 48px 48px 64px;
        }
    }
}
