.login-form
{
    .ant-btn-link
    {
        color: black;

        &:hover
        {
            color: inherit;
            opacity: 0.7;
        }
    }

}
