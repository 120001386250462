.dashboard-header {
    line-height: 1;
    color: black;
    background-color: white;
    border-bottom: 1px solid #ddd;
    padding: 0 24px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 20;

    .title-container {
        flex: 1 1 0;

        .title {
            font-size: 18px;
            font-weight: 600;
            color: black;
            display: inline-flex;
            align-items: center;

            .logo {
                border-radius: 4px;
                height: 20px;
                margin-right: 8px;
            }
        }
    }

    .ant-dropdown-link {
        font-size: 14px;
        text-align: right;
        color: black;
        display: flex;
        align-items: center;
        cursor: pointer;

        .anticon {
            font-size: 16px;
            margin-left: 8px;
        }
    }
}
