@import '~antd/dist/antd.css';

body
{
    background-color: #f0f2f5;
}
strong
{
    font-weight: 600;
}

.subtitle
{
    font-size: 12px;
    color: #888;
}

.ant-btn-primary
{
    background-color: #000;
    border-color: #000;
    --antd-wave-shadow-color: #000;

    &:hover, &:focus
    {
        background-color: #222;
        border-color: #222;
    }
}

.color-pin
{
    width: 280px;
    padding-top: 16px;

    .ant-notification-notice-message
    {
        display: none;
    }
}
