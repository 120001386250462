.color-popover
{
    width: 300px;

    .name-row
    {
        display: flex;
        align-items: center;

        .color
        {
            border-radius: 4px;
            width: 32px;
            height: 32px;
        }

        .name-col
        {
            margin-left: 12px;
        }
        .name-title
        {
            font-size: 12px;
            line-height: 1;
            color: #888;
        }
        .name-subtitle
        {
            line-height: 1;
            margin-top: 4px;
        }
    }
    .color-values
    {
        display: flex;

        div
        {
            text-align: center;
            flex: 1 1 0;
        }
    }


    .similar-color
    {
        margin-bottom: 12px;
        display: flex;

        &:hover
        {
            .color
            {
                .pin-icon
                {
                    visibility: visible;
                }
            }
        }

        .color
        {
            font-size: 16px;
            border-radius: 4px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover
            {
                .pin-icon
                {
                    opacity: 0.7;
                    transition: opacity 0.2s;
                }
            }

            .pin-icon
            {
                visibility: hidden;
                transition: opacity 0.2s;
            }
        }

        .item-col
        {
            margin-left: 12px;
        }
        .color-title
        {
            font-size: 12px;
            line-height: 1;
            color: #888;
        }
        .color-subtitle
        {
            line-height: 1;
            margin-top: 3px;
        }
        .color-code
        {
            font-size: 12px;
        }
    }
}
