.tint-match-order-line-item
{
    &:hover
    {
        .delete-btn-col
        {
            .delete-btn
            {
                display: inline-block;
            }
        }
    }

    .color-col
    {
        display: flex;
        align-items: center;

        .color-circle
        {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
        .color-text
        {
            line-height: 1.1;
            display: block;
        }
    }
    .delete-btn-col
    {
        color: #888;
        cursor: pointer;
        transition: 0.2s;

        &:hover
        {
            opacity: 0.7;
            transition: 0.2s;
        }

        .delete-btn
        {
            display: none;
        }
    }
}
