.cohort-chart {
    .cell {
        text-align: center;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        padding: 4px 0;
        display: flex;
        justify-content: center;
        align-items: baseline;
        cursor: pointer;

        &.top {
            border-bottom: 1px solid #f0f0f0;
        }
        &.left {
            border-right: 1px solid #f0f0f0;
        }

        &.row-selected {
            border-top: 1px solid #1890ff;
            border-bottom: 1px solid #1890ff;
        }
        &.cell-selected {
            border: 1px solid #1890ff;
        }
    }

    .cell-header {
        font-weight: 600;
        background-color: #fafafa;
        padding: 2px 0;
        align-items: center;
    }
}
