.tint-match-colours-panel
{
    .ant-table-row
    {
        &:hover
        {
            .pin-btn
            {
                pointer-events: auto;
                visibility: visible;
            }
        }

        .pin-btn
        {
            font-size: 16px;
            width: 32px;
            height: 32px;
            margin-left: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            pointer-events: none;
            visibility: hidden;
            transition: opacity 0.2s;

            &:hover
            {
                opacity: 0.7;
                transition: opacity 0.2s;
            }
        }
    }
}
