.tint-match-search-list-item
{
    margin-bottom: 24px;
    padding: 0 8px 0 28px;
    display: flex;
    align-items: center;

    &:hover
    {
        .pin-btn
        {
            .pin-icon
            {
                visibility: visible;
            }
        }
    }

    .color
    {
        border-radius: 4px;
        width: 64px;
        height: 64px;
    }
    .pin-btn
    {
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover
        {
            .pin-icon
            {
                opacity: 0.7;
                transition: opacity 0.2s;
            }
        }

        .pin-icon
        {
            visibility: hidden;
            transition: opacity 0.2s;
        }
    }

    .item-col
    {
        margin-left: 12px;
    }
    .item-title
    {
        font-size: 12px;
        line-height: 1;
        color: #888;
    }
}
