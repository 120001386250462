.tint-match-search-list
{
    .tint-match-search-list-reference
    {
        margin-bottom: 24px;
        padding: 0 8px 0 28px;
        display: flex;
        align-items: center;

        .color
        {
            border-radius: 4px;
            width: 64px;
            height: 48px;
        }

        .item-col
        {
            margin-left: 12px;
        }
        .item-title
        {
            font-size: 12px;
            line-height: 1;
            color: #888;
        }
    }
}
